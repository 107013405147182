import BaseModal from '@/components/global/base-modal';
import LogInOrCreateForm from '@/components/global/log-in-or-create-form';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { CurrentUserType } from '@/queries/current-user';
import { type BaseTrackingProperties, ModalTypes } from '@/types/tracking';

export enum OpenWithTypes {
    LOGIN = 'log-in',
    SIGNUP = 'sign-up',
}

type LogInOrCreateModalProps = {
    isOpen: boolean;
    closeFn: () => void;
    signUpCopy?: React.ReactNode;
    logInCopy?: React.ReactNode;
    openWith?: `${OpenWithTypes}`;
    onLoginSuccess?: (user: CurrentUserType) => void;
    trackingProperties?: Omit<BaseTrackingProperties, 'component'>;
};

const LogInOrCreateModal = ({
    closeFn,
    isOpen,
    signUpCopy,
    logInCopy,
    onLoginSuccess,
    openWith = 'sign-up',
    trackingProperties = {} as BaseTrackingProperties,
}: LogInOrCreateModalProps) => {
    const { categories, components } = usePosthogTracking();

    return (
        <BaseModal
            isOpen={isOpen}
            onRequestClose={closeFn}
            header="Welcome to Thematic"
            headerClassName="text-center"
            className="rounded-lg border border-analyst-dark-lavender shadow-lg pt-4"
            size="1/3"
            trackingProperties={{
                ...trackingProperties,
                category: categories.LOGIN_SIGNUP,
                component: components.LOGIN_SIGNUP_MODAL,
                modalType: ModalTypes.LOGIN_SIGNUP,
            }}
        >
            <div className="px-8 pb-8">
                <LogInOrCreateForm {...{ logInCopy, onLoginSuccess, openWith, signUpCopy }} />
            </div>
        </BaseModal>
    );
};
export default LogInOrCreateModal;
