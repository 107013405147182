import { gql } from 'graphql-request';
import { useParams } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@/components/global/button';
import ConfirmModal from '@/components/global/confirm-modal';
import { useGlobalState } from '@/components/global/global-state';
import Icon from '@/components/global/icon';
import LogInOrCreateModal from '@/components/global/log-in-or-create-modal';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { GQL_CLIENT } from '@/lib/graphql';

type IndexFollowButtonProps = {
    indexId: string;
    indexName: string;
    mini?: boolean;
};

type FollowButtonProps = {
    mini: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    isFollowing: boolean;
};

const FollowButton = ({ mini, onClick, isFollowing }: FollowButtonProps) => {
    const { eventTypes } = usePosthogTracking();

    return (
        <Button
            type="action"
            onClick={onClick}
            color="transparent"
            roundedCorners="md"
            className="flex items-center hover:bg-brand-navy-light border border-brand-unicorn !p-2.5 !text-brand-unicorn"
            title={isFollowing ? 'Unfollow index' : 'Follow index'}
            tracking={{
                eventType: isFollowing ? eventTypes.UNFOLLOW_INDEX : eventTypes.FOLLOW_INDEX,
                trackingProperties: {
                    iconType: isFollowing ? 'minus' : 'plus',
                    label: isFollowing ? 'Unfollow' : 'Follow',
                },
            }}
        >
            <Icon
                type={isFollowing ? 'minus' : 'plus'}
                size={mini ? 'xs' : 'base'}
                color="unicorn"
            />
            {!mini && <span className="ml-2">{isFollowing ? 'Unfollow' : 'Follow'}</span>}
        </Button>
    );
};

// TODO: consider taking in initial state as props to avoid N+1 when included for multiple indexes on the same page
const IndexFollowButton = ({ indexId, indexName, mini = false }: IndexFollowButtonProps) => {
    const params = useParams<{ symbol?: string }>();
    const { eventTypes, trackManualEvent } = usePosthogTracking();
    const { globalState } = useGlobalState();
    const [isLoading, setIsLoading] = useState(true);
    const [isFollowed, setIsFollowed] = useState(false);
    const [showLogInModal, setShowLogInModal] = useState(false);
    const [followIntent, setFollowIntent] = useState(false);
    const [showUnfollowModal, setShowUnfollowModal] = useState(false);

    const follow = useCallback(() => {
        const query = gql`
            mutation FollowIndex($userId: ID!, $indexId: ID!) {
                followIndex(input: { userId: $userId, indexId: $indexId }) {
                    __typename
                    ... on FieldErrors {
                        errors {
                            message
                        }
                    }
                }
            }
        `;

        const variables = {
            indexId,
            userId: globalState.currentUser?.id,
        };

        setIsFollowed(true);

        GQL_CLIENT.request(query, variables)
            .then(
                ({
                    followIndex,
                }: {
                    followIndex:
                        | { __typename: 'FollowIndexSuccess' }
                        | { __typename: 'FieldErrors'; errors: Array<{ message: string }> };
                }) => {
                    if (followIndex.__typename === 'FieldErrors') {
                        setIsFollowed(false);
                        toast.error('There was an error following this index.');
                        trackManualEvent({
                            eventType: eventTypes.FOLLOW_INDEX_ERROR,
                            trackingProperties: {
                                errorType: followIndex.errors.map(({ message }) => message).join(', '),
                                id: indexId,
                                label: indexName,
                                symbol: params?.symbol,
                            },
                        });
                    } else {
                        trackManualEvent({
                            eventType: eventTypes.FOLLOW_INDEX_SUCCESS,
                            trackingProperties: {
                                id: indexId,
                                label: indexName,
                                symbol: params?.symbol,
                            },
                        });
                    }
                }
            )
            .catch((error) => {
                toast.error(error);
            });
    }, [
        eventTypes.FOLLOW_INDEX_ERROR,
        eventTypes.FOLLOW_INDEX_SUCCESS,
        globalState.currentUser?.id,
        indexId,
        indexName,
        params?.symbol,
        trackManualEvent,
    ]);

    const unfollow = useCallback(() => {
        const query = gql`
            mutation UnfollowIndex($userId: ID!, $indexId: ID!) {
                unfollowIndex(input: { userId: $userId, indexId: $indexId }) {
                    ... on FollowIndexSuccess {
                        success
                    }
                    ... on FieldErrors {
                        errors {
                            message
                        }
                    }
                }
            }
        `;

        const variables = {
            indexId,
            userId: globalState.currentUser?.id,
        };

        setIsFollowed(false);

        GQL_CLIENT.request(query, variables)
            .then(
                ({
                    unfollowIndex,
                }: {
                    unfollowIndex:
                        | { __typename: 'FollowIndexSuccess' }
                        | { __typename: 'FieldErrors'; errors: Array<{ message: string }> };
                }) => {
                    if (unfollowIndex.__typename === 'FieldErrors') {
                        setIsFollowed(true);
                        toast.error('There was an error unfollowing this index.');
                        trackManualEvent({
                            eventType: eventTypes.UNFOLLOW_INDEX_ERROR,
                            trackingProperties: {
                                errorType: unfollowIndex.errors.map(({ message }) => message).join(', '),
                                id: indexId,
                                label: indexName,
                                symbol: params?.symbol,
                            },
                        });
                    } else {
                        trackManualEvent({
                            eventType: eventTypes.UNFOLLOW_INDEX_SUCCESS,
                            trackingProperties: {
                                id: indexId,
                                label: indexName,
                                symbol: params?.symbol,
                            },
                        });
                    }
                }
            )
            .catch((error) => {
                toast.error(error);
            });
    }, [
        eventTypes.UNFOLLOW_INDEX_ERROR,
        eventTypes.UNFOLLOW_INDEX_SUCCESS,
        globalState.currentUser?.id,
        indexId,
        indexName,
        params?.symbol,
        trackManualEvent,
    ]);

    const followLoggedOut = () => {
        setFollowIntent(true);
        setShowLogInModal(true);
    };

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        if (isLoading || globalState.currentUserLoading) {
            return;
        }

        if (globalState.currentUser && followIntent) {
            // A logged out user wanted to follow, now they just logged in
            setFollowIntent(false);
            follow();
            return;
        }
    }, [globalState.currentUser, globalState.currentUserLoading, isLoading, follow, setFollowIntent, followIntent]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const query = gql`
            query CurrentUser($indexId: ID!) {
                currentUser {
                    isFollowingIndex(indexId: $indexId)
                }
            }
        `;

        GQL_CLIENT.request(query, { indexId })
            .then((data: { currentUser: Optional<{ isFollowingIndex: boolean }> }) => {
                const user = data.currentUser ? data.currentUser : null;

                setIsFollowed(!!user && user.isFollowingIndex);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.error(error);
            });
    }, [
        globalState.currentUser,
        globalState.currentUserLoading,
        indexId,
        isFollowed,
        setIsFollowed,
        isLoading,
        setIsLoading,
    ]);

    // Loading
    if (globalState.currentUserLoading || isLoading) {
        return <></>;
    }

    if (!globalState.currentUser) {
        // Logged out button
        return (
            <>
                <LogInOrCreateModal
                    closeFn={() => setShowLogInModal(false)}
                    isOpen={showLogInModal}
                />
                <FollowButton
                    mini={mini}
                    onClick={followLoggedOut}
                    isFollowing={false}
                />
            </>
        );
    }

    return (
        <>
            {isFollowed && (
                <ConfirmModal
                    message={'Unfollow ' + indexName + '?'}
                    isOpen={showUnfollowModal}
                    onConfirm={() => {
                        unfollow();
                        setShowUnfollowModal(false);
                    }}
                    onRequestClose={() => setShowUnfollowModal(false)}
                    trackingProperties={{
                        actionType: eventTypes.UNFOLLOW_INDEX,
                    }}
                />
            )}
            <FollowButton
                mini={mini}
                onClick={() => {
                    if (isFollowed) {
                        setShowUnfollowModal(true);
                    } else {
                        follow();
                    }
                }}
                isFollowing={isFollowed}
            />
        </>
    );
};

export default IndexFollowButton;
