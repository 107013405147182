import Link from 'next/link';
import { useState } from 'react';

import { Heading } from '@/components/dom/text-elements';
import Button from '@/components/global/button';
import Icon from '@/components/global/icon';
import IndexShareModal from '@/components/global/index-share-modal';
import Graph from '@/components/graph/index-graph';
import IndexFollowButton from '@/components/index/follow-button';
import { NormalizeIndexData } from '@/components/index/helpers';
import Organization from '@/components/products/organization';
import PerformanceChange from '@/components/products/performance-change';
import { CalcPercentDiff } from '@/helpers/numbers';
import { GetIndexSymbolPublicPath, GetOrganizationPublicRedirectPath } from '@/helpers/paths';
import cn from '@/lib/cn';
import { IndexCardType } from '@/queries/index-card';

type Props = {
    index: IndexCardType;
    className?: string;
};

type TitleProps = {
    title: string;
    href: string;
};

const CardTitle = ({ title, href }: TitleProps) => {
    return (
        <Heading
            importance={5}
            className="text-brand-blue-yves text-left mb-2 line-clamp-1"
        >
            <Link
                href={href}
                className="no-underline text-brand-blue-yves hover:text-brand-blue-nu visited:text-brand-blue-yves line-clamp-2"
                title={title}
            >
                {title}
            </Link>
        </Heading>
    );
};

export const IndexCard = ({
    index: { id, name, symbol, threeMonthDailySnapshots, organization },
    className,
}: Props) => {
    const [showShareModal, setShowShareModal] = useState(false);
    const { normalizedIndexData, symbolList } = NormalizeIndexData(threeMonthDailySnapshots, symbol);
    let graph = null;
    let indexChange = null;

    const indexUrl = GetIndexSymbolPublicPath(symbol);

    if (normalizedIndexData.length) {
        const firstItem = normalizedIndexData[0];
        const lastItem = normalizedIndexData[normalizedIndexData.length - 1];
        // calculate the total change since inception for the index
        indexChange = CalcPercentDiff(firstItem.symbolValues[symbol], lastItem.symbolValues[symbol]);
        let color = '#02D8C3';
        if (indexChange < 0) {
            color = '#D80275';
        }

        graph = (
            <Graph
                indexData={normalizedIndexData}
                symbolList={symbolList}
                height={150}
                showTooltip={false}
                showLegend={false}
                showXLabels={false}
                showYLabels={false}
                cursor="pointer"
                lineColor={color}
            />
        );
    }

    return (
        <div className={cn('flex flex-col bg-white justify-between p-6 mb-6 h-[350px] rounded-md', className)}>
            <div>
                <div className="mb-2">
                    <Link
                        href={GetOrganizationPublicRedirectPath(organization.id)}
                        className="no-underline text-brand-blue-yves hover:text-brand-blue-nu visited:text-brand-blue-yves"
                        title={organization.name}
                    >
                        <Organization organization={organization} />
                    </Link>
                </div>
                <CardTitle
                    title={name}
                    href={indexUrl}
                />
            </div>
            <div>
                <Link
                    href={indexUrl}
                    className="cursor-pointer no-underline flex-grow"
                >
                    <div>
                        {graph ? (
                            <div className="mb-2 border border-brand-gray-med">{graph}</div>
                        ) : (
                            <div className="mb-2 bg-brand-gray flex h-[150px] justify-center items-center">
                                <Icon
                                    type="indexDraft"
                                    color="gray"
                                />
                            </div>
                        )}
                    </div>
                </Link>
                <div className={cn('flex justify-between items-center', !indexChange && 'justify-end')}>
                    {indexChange && (
                        <div className="flex items-end">
                            <PerformanceChange
                                percentChange={indexChange}
                                hasBackground={true}
                            />
                            <span className="ml-2 text-xs text-brand-purple-deep pb-[4px] font-brand-bold">
                                3 months
                            </span>
                        </div>
                    )}

                    <div className="flex shrink-0 gap-2.5">
                        <>
                            {/* TODO: extract to a component */}
                            <IndexShareModal
                                isOpen={showShareModal}
                                closeFn={() => setShowShareModal(false)}
                                indexSymbol={symbol}
                            />
                            <Button
                                // TODO: TRACKING
                                type="action"
                                onClick={() => setShowShareModal(true)}
                                color="transparent"
                                roundedCorners="md"
                                className="flex items-center hover:bg-brand-navy-light border border-brand-blue-nu !p-2.5 !text-brand-blue-nu"
                                title="Share"
                            >
                                <Icon
                                    type="shareArrow"
                                    size="xs"
                                    color="blueNu"
                                />
                            </Button>
                        </>
                        <div className={cn('flex justify-between items-center')}>
                            <IndexFollowButton
                                indexId={id}
                                indexName={name}
                                mini={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
