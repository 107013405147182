import Icon from '@/components/global/icon';
import { FormatPercentage } from '@/helpers/numbers';
import cn from '@/lib/cn';

export type PerformanceChangeProps = {
    percentChange: number | null;
    hasBackground?: boolean;
    iconClassName?: string;
    textClassName?: string;
};

const PerformanceChange = ({
    percentChange,
    hasBackground = false,
    iconClassName,
    textClassName,
}: PerformanceChangeProps) => {
    if (!percentChange) {
        return <></>;
    }

    return (
        <div
            className={cn('flex items-center px-2 rounded-sm', {
                'bg-brand-danger-light': percentChange < 0 && hasBackground,
                'bg-brand-teal-light': percentChange >= 0 && hasBackground,
            })}
        >
            <Icon
                type="performanceArrow"
                rotate={percentChange >= 0 ? undefined : 180}
                color={percentChange >= 0 ? 'teal' : 'danger'}
                className={cn('h-[5px] w-[10px] mr-2', iconClassName)}
                size="custom"
            />
            <span
                className={cn(
                    'text-lg font-brand-bold',
                    {
                        'text-brand-danger': percentChange < 0,
                        'text-brand-teal': percentChange >= 0,
                    },
                    textClassName
                )}
            >
                {FormatPercentage(percentChange)}
            </span>
        </div>
    );
};

export default PerformanceChange;
