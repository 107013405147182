import BaseModal from '@/components/global/base-modal';
import Button from '@/components/global/button';
import { type BaseTrackingProperties, ModalTypes } from '@/types/tracking';

type ConfirmModalProps = {
    message: string;
    isOpen: boolean;
    onConfirm: () => void;
    onRequestClose: () => void;
    confirmText?: string;
    trackingProperties?: BaseTrackingProperties;
};

const ConfirmModal = ({
    message,
    isOpen,
    onConfirm,
    onRequestClose,
    confirmText = 'Confirm',
    trackingProperties = {},
}: ConfirmModalProps) => {
    return (
        <BaseModal
            header={message}
            allowClose={false}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            size="1/3"
            trackingProperties={{
                ...trackingProperties,
                modalType: ModalTypes.CONFIRM,
            }}
        >
            <div className="flex justify-center p-6">
                <Button
                    // TODO: TRACKING
                    type="action"
                    onClick={onConfirm}
                    color="primary"
                    className="px-2 mr-4 font-brand-bold"
                    roundedCorners="md"
                >
                    {confirmText}
                </Button>
                <Button
                    // TODO: TRACKING
                    type="action"
                    onClick={onRequestClose}
                    color="transparent"
                    className="px-2 mr-4 color !text-brand-blue-nu border border-brand-blue-nu font-brand-bold"
                    roundedCorners="md"
                >
                    Cancel
                </Button>
            </div>
        </BaseModal>
    );
};

export default ConfirmModal;
