import { TeamAvatar } from '@/components/global/avatar';

type OrganizationType = {
    name: string;
    avatarUrl: Optional<string>;
};

type Props = {
    organization: OrganizationType;
};

const Organization = ({ organization: { name, avatarUrl } }: Props) => {
    return (
        <div className="flex items-center">
            <TeamAvatar
                url={avatarUrl}
                name={name}
                className="w-8 h-8 !text-base shrink-0"
            />
            <span className="ml-3 font-brand-bold line-clamp-1">{name}</span>
        </div>
    );
};

export default Organization;
